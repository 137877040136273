@charset "UTF-8";
/*--------------------------------------------------------
　BREAK POINT
--------------------------------------------------------*/
/*--------------------------------------------------------
　FONT
--------------------------------------------------------*/
/*--------------------------------------------------------
　EASING
--------------------------------------------------------*/
/*--------------------------------------------------------
　MIXIN
--------------------------------------------------------*/
/*--------------------------------------------------------
　STYLE RESET
--------------------------------------------------------*/
html {
  overflow: auto;
}

body {
  -webkit-print-color-adjust: exact;
  -webkit-text-size-adjust: 100%;
  text-align: left;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td, figure, figcaption {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  text-align: left;
}

q:before, q:after {
  content: "";
}

object, embed {
  vertical-align: top;
}

hr, legend {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

img, abbr, acronym, fieldset {
  border: none;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  border: none;
  outline: none;
}

/*--------------------------------------------------------
　BASE STYLE
--------------------------------------------------------*/
body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  color: #000000;
  background: #FFFFFF;
}

.allwrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.br {
  display: inline-block;
}

@media (min-width: 760px) {
  .sp-visible {
    display: none;
  }
}
@media (max-width: 759px) {
  .pc-visible {
    display: none;
  }
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 759px) {
  img {
    width: 100%;
  }
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
}

@media (min-width: 960px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
/*--------------------------------------------------------
　ANIMATION
--------------------------------------------------------*/
.js-anm.fadeup {
  opacity: 0;
  transform: translate(0, 40px);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.8s ease;
}
.js-anm.fadeup.is-done {
  opacity: 1;
  transform: translate(0, 0);
}
.js-anm.fadedown {
  opacity: 0;
  transform: translate(0, -40px);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.8s ease;
}
.js-anm.fadedown.is-done {
  opacity: 1;
  transform: translate(0, 0);
}
.js-anm.fadeleft {
  opacity: 0;
  transform: translate(-40px, 0);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.8s ease;
}
.js-anm.fadeleft.is-done {
  opacity: 1;
  transform: translate(0, 0);
}
.js-anm.faderight {
  opacity: 0;
  transform: translate(40px, 0);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.8s ease;
}
.js-anm.faderight.is-done {
  opacity: 1;
  transform: translate(0, 0);
}
.js-anm.fadescale {
  opacity: 0;
  transform: scale(0);
  transition: all 1s cubic-bezier(0.65, -0.55, 0.265, 1.55);
}
.js-anm.fadescale.is-done {
  opacity: 1;
  transform: scale(1);
}

.l {
  /*--------------------------------------------------------
   HEADER
  --------------------------------------------------------*/
  /*--------------------------------------------------------
   NAV
  --------------------------------------------------------*/
  /*--------------------------------------------------------
   MAIN
  --------------------------------------------------------*/
  /*--------------------------------------------------------
   FOLLOW
  --------------------------------------------------------*/
  /*--------------------------------------------------------
   FOOTER
  --------------------------------------------------------*/
  /*--------------------------------------------------------
   PAGETOP
  --------------------------------------------------------*/
}
.l-pc-header {
  width: 100%;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 10;
}
.l-pc-header.is-ready-pre {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  transform: translate(0, -125px);
  opacity: 0;
}
.l-pc-header.is-ready {
  opacity: 1;
  transform: translate(0, -125px);
}
.l-pc-header.is-ready.is-fixed {
  transform: translate(0, 0);
}
.l-pc-header__inner {
  width: 100%;
  height: 110px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.l-pc-header__logo {
  flex: 1;
}
.l-pc-header__logo a {
  display: none;
  width: 103px;
  margin: 0 0 0 40px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (hover: hover) {
  .l-pc-header__logo a:hover {
    opacity: 0.7;
  }
}
.is-ready .l-pc-header__logo a {
  display: block;
}
@media (max-width: 959px) {
  .l-pc-header {
    display: none;
  }
}
.l-sp-header {
  display: none;
}
@media (max-width: 959px) {
  .l-sp-header {
    display: block;
    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .l-sp-header::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -100%);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .l-sp-header__logo {
    width: 85px;
    position: absolute;
    top: 8px;
    left: 26px;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .l-sp-header__logo a {
    display: block;
  }
  .l-sp-header.is-fixed::before {
    transform: translate(0, 0);
  }
  .l-sp-header.is-fixed .l-sp-header__logo {
    opacity: 1;
  }
}
.l-pc-nav {
  flex-basis: 815px;
}
.l-pc-nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  line-height: 1;
}
.l-pc-nav li {
  position: relative;
}
.l-pc-nav li::before {
  content: "";
  width: 1px;
  height: 20px;
  background: #000000;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
}
.l-pc-nav li:nth-last-of-type(2)::after {
  content: "";
  width: 1px;
  height: 20px;
  background: #000000;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
}
.l-pc-nav li a {
  display: block;
  padding: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (hover: hover) {
  .l-pc-nav li a:hover {
    opacity: 0.7;
  }
}
.l-pc-nav li.contact {
  background: #000000;
  color: #FFFFFF;
  margin-left: 20px;
}
.l-pc-nav li.contact::before {
  display: none;
}
.l-pc-nav li.contact a {
  padding: 20px;
}
.l-sp-nav {
  width: 100%;
  padding: 50px 0 150px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overscroll-behavior-y: none;
  transform: translate(100%, 0);
}
.l-sp-nav::before {
  content: "";
  width: 150vw;
  height: 100%;
  background: #f7f7f7;
  border-radius: 0 0 75vw 75vw;
  position: absolute;
  top: 0;
  left: 0;
}
.l-sp-nav__inner {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.l-sp-nav__list {
  width: 260px;
}
.l-sp-nav__list li {
  opacity: 0;
  padding: 10px 0;
  transition: opacity 0.5s ease;
}
.l-sp-nav__list li a {
  display: block;
}
.l-sp-nav__list li a .en {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 25px;
  font-weight: 300;
  line-height: 1;
}
.l-sp-nav__list li a .jp {
  display: block;
  font-size: 12px;
}
.l-sp-nav__list li:nth-child(0) {
  transition-delay: 0.7s;
}
.l-sp-nav__list li:nth-child(1) {
  transition-delay: 0.7333333333s;
}
.l-sp-nav__list li:nth-child(2) {
  transition-delay: 0.7666666667s;
}
.l-sp-nav__list li:nth-child(3) {
  transition-delay: 0.8s;
}
.l-sp-nav__list li:nth-child(4) {
  transition-delay: 0.8333333333s;
}
.l-sp-nav__list li:nth-child(5) {
  transition-delay: 0.8666666667s;
}
.l-sp-nav__list li:nth-child(6) {
  transition-delay: 0.9s;
}
.l-sp-nav__list li:nth-child(7) {
  transition-delay: 0.9333333333s;
}
.l-sp-nav__list li:nth-child(8) {
  transition-delay: 0.9666666667s;
}
.l-sp-nav__list li:nth-child(9) {
  transition-delay: 1s;
}
.l-sp-nav__list li:nth-child(10) {
  transition-delay: 1.0333333333s;
}
.is-open-menu .l-sp-nav {
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.l-sp-nav.is-open {
  transform: translate(0, 0);
}
.l-sp-nav.is-open .l-sp-nav__list li {
  opacity: 1;
}
@keyframes menu-line01 {
  0% {
    margin-top: -13px;
    transform: rotate(0deg);
  }
  50% {
    margin-top: 0;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 0;
    transform: rotate(-45deg);
  }
}
@keyframes menu-line02 {
  0% {
    margin-top: 10px;
    transform: rotate(0deg);
  }
  50% {
    margin-top: 0;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 0;
    transform: rotate(45deg);
  }
}
@keyframes menu-line01-re {
  0% {
    margin-top: 0;
    transform: rotate(-45deg);
  }
  50% {
    margin-top: 0;
    transform: rotate(0deg);
  }
  100% {
    margin-top: -13px;
    transform: rotate(0deg);
  }
}
@keyframes menu-line02-re {
  0% {
    margin-top: 0;
    transform: rotate(45deg);
  }
  50% {
    margin-top: 0;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 10px;
    transform: rotate(0deg);
  }
}
.l-sp-nav__btn {
  width: 32px;
  height: 26px;
  position: absolute;
  top: 20px;
  right: 26px;
  z-index: 1;
}
.l-sp-nav__btn::before, .l-sp-nav__btn::after {
  content: "";
  width: 32px;
  height: 4px;
  background: #000000;
  position: absolute;
  top: 50%;
  left: 0;
}
.l-sp-nav__btn::before {
  animation: menu-line01-re 0.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
.l-sp-nav__btn::after {
  animation: menu-line02-re 0.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
.l-sp-nav__btn .line {
  display: block;
  width: 32px;
  height: 4px;
  background: #000000;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -2px;
  opacity: 1;
  transition: 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.l-sp-nav__btn .txt {
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 38px;
  transition: 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.l-sp-nav__btn.is-open::before {
  animation: menu-line01 0.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
.l-sp-nav__btn.is-open::after {
  animation: menu-line02 0.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
.l-sp-nav__btn.is-open .line, .l-sp-nav__btn.is-open .txt {
  opacity: 0;
}
.l-follow {
  display: none;
}
@media (max-width: 959px) {
  .l-follow {
    display: block;
    width: 100%;
    height: 45px;
    background: #000000;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
  .l-follow a {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    line-height: 1;
  }
  .l-follow a .en {
    display: block;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
  }
  .l-follow a .jp {
    font-size: 12px;
    margin: 0 1em 0 0.5em;
  }
  .l-follow a .jp::before {
    content: "｜";
    display: inline-block;
    margin-right: 0.5em;
  }
  .l-follow a .arrow {
    width: 6px;
    height: 12px;
    line-height: 1;
  }
  .l-follow a .arrow img {
    vertical-align: top;
  }
}
.l-footer {
  background: #f4f4f4;
}
.l-footer__nav {
  margin-bottom: 90px;
}
.l-footer__nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  line-height: 1;
}
.l-footer__nav li {
  position: relative;
}
.l-footer__nav li::before {
  content: "";
  width: 1px;
  height: 20px;
  background: #000000;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
}
.l-footer__nav li:last-of-type::after {
  content: "";
  width: 1px;
  height: 20px;
  background: #000000;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
}
.l-footer__nav li a {
  display: block;
  padding: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (hover: hover) {
  .l-footer__nav li a:hover {
    opacity: 0.7;
  }
}
.l-footer__bottom {
  background: #000000;
  padding: 100px 0;
  position: relative;
}
.l-footer__logo {
  width: 217px;
  margin: 0 auto 36px;
}
.l-footer__logo a {
  display: block;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (hover: hover) {
  .l-footer__logo a:hover {
    opacity: 0.7;
  }
}
.l-footer__copyright {
  text-align: center;
  font-size: 11px;
  color: #FFFFFF;
}
@media (max-width: 959px) {
  .l-footer {
    background: #000000;
    padding: 0 7.2vw;
    position: relative;
  }
  .l-footer__nav {
    margin-bottom: 0;
    padding-top: 50px;
  }
  .l-footer__nav ul {
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 300;
    color: #FFFFFF;
  }
  .l-footer__nav li {
    width: calc((100% - 20px) / 3);
    border: 1px solid #FFFFFF;
    margin-bottom: 10px;
    text-align: center;
  }
  .l-footer__nav li::before {
    display: none;
  }
  .l-footer__nav li:last-of-type::after {
    display: none;
  }
  .l-footer__nav li:nth-child(4), .l-footer__nav li:nth-child(5) {
    width: calc((100% - 10px) / 2);
  }
  .l-footer__bottom {
    position: static;
    padding: 70px 0 60px;
  }
  .l-footer__logo {
    width: 180px;
    margin-bottom: 50px;
  }
  .l-footer__copyright {
    font-size: 11px;
  }
}
.l-pagetop {
  width: 63px;
  height: 63px;
  position: absolute;
  top: -126px;
  right: 50px;
  z-index: 10;
}
.l-pagetop a {
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media (hover: hover) {
  .l-pagetop a:hover {
    opacity: 0.7;
  }
}
@media (max-width: 959px) {
  .l-pagetop {
    width: 52px;
    height: 52px;
    top: -82px;
    right: 7.2vw;
  }
  .l-pagetop a img {
    width: 10px;
  }
}

.c-filter {
  pointer-events: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -moz-touch-callout: none !important;
  -moz-user-select: none !important;
  touch-callout: none !important;
  user-select: none !important;
}
.c-inner {
  max-width: 828px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 759px) {
  .c-inner {
    padding-left: 7.2vw;
    padding-right: 7.2vw;
  }
}

/*--------------------------------------------------------
　MARGIN TOP
--------------------------------------------------------*/
.u-mt10 {
  margin-top: 10px;
}
.u-mt20 {
  margin-top: 20px;
}
.u-mt30 {
  margin-top: 30px;
}
.u-mt40 {
  margin-top: 40px;
}
.u-mt50 {
  margin-top: 50px;
}
.u-mt60 {
  margin-top: 60px;
}
.u-mt70 {
  margin-top: 70px;
}
.u-mt80 {
  margin-top: 80px;
}
.u-mt90 {
  margin-top: 90px;
}
.u-mt100 {
  margin-top: 100px;
}

/**
 * SPORTS WELL-BEING 共通CSS
 * Created on: 2023.9.8
 * Modified on: YYYY.MM.DD
 */
